import { useState } from "react";
import SurveyForm from "./components/SurveyForm";
import LoadingScreen from "./components/LoadingScreen";
import SubmissionResult from "./components/SubmissionResult";
import { useSurveyModel } from "./hooks/useSurveyModel";
import { useSurveySubmission } from "./hooks/useSurveySubmission";
import { downloadPDF } from "./utils/pdfUtils";
import ErrorScreen from "./components/ErrorScreen"; // Import the ErrorScreen component

function App() {
  const [pdfData, setPdfData] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [onSubmitting, setOnSubmitting] = useState(false);
  const [error, setError] = useState(null); // Add error state

  const handleOnCompleting = useSurveySubmission(
    setPdfData,
    setIsSubmitted,
    setOnSubmitting,
    setError // Pass the setError function to the hook
  );
  const surveyModel = useSurveyModel(handleOnCompleting);

  return (
    <div data-testid="app">
      {!isSubmitted && !onSubmitting && !error && (
        <SurveyForm surveyModel={surveyModel}  />
      )}
      {!isSubmitted && onSubmitting && !error && <LoadingScreen />}
      {isSubmitted && !onSubmitting && !error && (
        <SubmissionResult
          onDownloadPDF={() => downloadPDF(pdfData)}
          pdfData={pdfData}
        />
      )}
      {/* Render ErrorScreen when there's an error */}
      {error && <ErrorScreen error={error} />}{" "}
    </div>
  );
}

export default App;

import { PDFDocument, rgb } from "pdf-lib";
import { SurveyPDF } from "survey-pdf";

export const surveyGeneratePDF = async (defaultSurveyJson, sender) => {
  const surveyPdf = new SurveyPDF(defaultSurveyJson);

  // Get the selected value for Grub24 Sales Owner Name
  const selectedValue = sender.data.grub24SalesOwnerName;

  // Find the corresponding text from the choices
  const grub24SalesOwnerQuestion = sender.getQuestionByName(
    "grub24SalesOwnerName"
  );

  const surveyData = { ...sender.data };

  let selectedChoice;
  if (selectedValue && grub24SalesOwnerQuestion) {
    selectedChoice = grub24SalesOwnerQuestion.choices.find(
      (choice) => choice.value === selectedValue
    );
    surveyData.grub24SalesOwnerName = selectedChoice.jsonObj.text;
  }

  surveyPdf.data = surveyData;

  const pdfBlob = await surveyPdf.raw("blob"); // Get PDF as a Blob
  const arrayBuffer = await pdfBlob.arrayBuffer();
  const pdfDoc = await PDFDocument.load(arrayBuffer);

  // Modify the PDF content
  const form = pdfDoc.getForm();
  // Flatten the form fields
  form.flatten();

  const pages = pdfDoc.getPages();
  pages.forEach((page) => {
    page.drawRectangle({
      x: 45, // Adjust x-coordinate based on the actual location of the text
      y: 815, // Adjust y-coordinate based on the actual location of the text
      width: 510, // Adjust width based on text width
      height: 20, // Adjust height based on text height
      color: rgb(1, 1, 1),
    });
  });

  // Generate a new Blob from the modified PDF
  const modifiedPdfBytes = await pdfDoc.save();
  const modifiedPdfBlob = new Blob([modifiedPdfBytes], {
    type: "application/pdf",
  });

  return modifiedPdfBlob;
};

export const downloadPDF = (pdfData, fileName = "agreement.pdf") => {
  if (pdfData) {
    const url = URL.createObjectURL(pdfData);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }, 0);
  }
};

// import html2pdf from "html2pdf.js";

// const generateCanvas = (input, pdfOptions) => {
//   return html2pdf().from(input).set(pdfOptions).toCanvas();
// };

// const generatePDFBlob = (input, pdfOptions) => {
//   return html2pdf().from(input).set(pdfOptions).toPdf().output("blob");
// };

// export const generatePDF = async () => {
//   try {
//     const input = document.getElementById("surveyContainer");
//     if (!input) {
//       throw new Error("No element found with the ID 'surveyContainer'");
//     }

//     const pdfOptions = {
//       margin: 5,
//       filename: "agreement.pdf",
//       image: { type: "jpeg", quality: 0.98 },
//       html2canvas: { scale: 2 },
//       jsPDF: { unit: "mm", format: "letter", orientation: "portrait" },
//       pagebreak: { mode: ["avoid-all", "css", "legacy"] },
//     };

//     input.style.backgroundColor = "white";
//     removeElementBySelector(input, "#cancel-preview > div");
//     removeElementBySelector(
//       input,
//       "div.sv-components-column.sv-components-container-contentBottom"
//     );

//     await generateCanvas(input, pdfOptions);

//     const pdfBlob = await generatePDFBlob(input, pdfOptions);

//     return pdfBlob;
//   } catch (error) {
//     console.error("Error generating PDF:", error);
//     throw error;
//   }
// };

// /**
//  * Removes an element from the DOM using a CSS selector.
//  * @param {string} selector - The CSS selector of the element to be removed.
//  */
// function removeElementBySelector(document, selector) {
//   // Select the element to be removed
//   const elementToRemove = document.querySelector(selector);

//   // Check if the element exists
//   if (elementToRemove) {
//     // Select the parent node
//     const parent = elementToRemove.parentNode;

//     // Remove the element from the parent node
//     parent.removeChild(elementToRemove);

//   } else {
//     console.error(`Element with selector '${selector}' does not exist.`);
//   }
// }

import React, { useState } from "react";
import App from "./App";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  IconButton,
  InputAdornment,
  Paper,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function Authentication() {
  const [authenticated, setAuthenticated] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleLogin = () => {
    if (password === process.env.REACT_APP_PASSWORD) {
      setAuthenticated(true);
    } else {
      setErrorMessage("Incorrect password. Please try again.");
      setPassword("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  if (!authenticated) {
    return (
      <Box sx={{ backgroundColor: "rgb(243, 243, 243)" }}>
        <Container maxWidth="sm">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="100vh"
          >
            <Paper elevation={3} sx={{ padding: 4, textAlign: "center" }}>
              <img
                src="logo144.png"
                alt="Company logo"
                style={{ marginBottom: "10px" }}
              />
              <Typography variant="h4" gutterBottom>
                Customer Agreement Form
              </Typography>

              {/* Accessible error message */}
              {errorMessage && (
                <Typography
                  role="alert"
                  aria-live="assertive"
                  color="error"
                  sx={{ mb: 2 }}
                >
                  {errorMessage}
                </Typography>
              )}

              <TextField
                color="primary"
                type={showPassword ? "text" : "password"}
                label="Enter Password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}
                fullWidth
                margin="normal"
                aria-describedby="password-helper-text"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={togglePasswordVisibility}
                        aria-label={
                          showPassword ? "Hide password" : "Show password"
                        }
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleLogin}
                fullWidth
                sx={{ mt: 2 }}
                aria-label="Submit password and login"
              >
                Enter
              </Button>
            </Paper>
          </Box>
        </Container>
      </Box>
    );
  }

  return <App />;
}

export default Authentication;

import { base64ToBlob } from "./documentUtils";

const backendDomain = process.env.REACT_APP_BACKEND_DOMAIN;
const backendAuthApiKey = process.env.REACT_APP_BACKEND_AUTH_API_KEY;
const freshsalesDomain = process.env.REACT_APP_CRM_DOMAIN;

const createHeaders = (ContentType = "application/json") => {
  const myHeaders = new Headers();
  if (ContentType) myHeaders.append("Content-Type", ContentType);
  myHeaders.append("Authorization", `Bearer ${backendAuthApiKey}`);
  return myHeaders;
};

const fetchFromApi = async (endpoint, requestOptions) => {
  try {
    const url = new URL(`${backendDomain}${endpoint}`);
    url.searchParams.append("hostname", freshsalesDomain);
    const response = await fetch(url, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error(`Error fetching ${endpoint}:`, error);
    throw error;
  }
};

export const fetchOwners = async () => {
  const requestOptions = {
    method: "GET",
    headers: createHeaders(),
  };
  return fetchFromApi("/crm/sales/api/selector/owners", requestOptions);
};

export const createAccount = async (inputData) => {
  const {
    businessName,
    streetAddress,
    city,
    postcode,
    grub24SalesOwnerName,
    businessPhoneNumber,
    description,
  } = inputData;
  const requestOptions = {
    method: "POST",
    headers: createHeaders(),
    body: JSON.stringify({
      sales_account: {
        name: businessName,
        phone: businessPhoneNumber,
        address: streetAddress,
        city: city,
        zipcode: postcode,
        custom_field: {
          cf_description: description,
        },
        owner_id: grub24SalesOwnerName,
      },
    }),
  };
  return fetchFromApi("/crm/sales/api/sales_accounts", requestOptions);
};

export const upsertContact = async (
  inputData,
  salesAccountId,
  // isEmailUniqueIdentifier
) => {
  const { firstName, lastName, workNumber, email, grub24SalesOwnerName } =
    inputData;

  const body = {
    contact: {
      first_name: firstName,
      last_name: lastName,
      sales_account_id: salesAccountId,
      owner_id: grub24SalesOwnerName,
      work_number: workNumber,
    },
    unique_identifier: {
      emails: email,
    },
  };

  // if (isEmailUniqueIdentifier) {
  //   body.unique_identifier = {
  //     emails: email,
  //   };
  //   body.contact.work_number = workNumber;
  // } else {
  //   body.unique_identifier = {
  //     work_number: workNumber,
  //   };
  //   body.contact.email = email;
  // }

  const requestOptions = {
    method: "POST",
    headers: createHeaders(),
    body: JSON.stringify(body),
  };
  return fetchFromApi("/crm/sales/api/contacts/upsert", requestOptions);
};

export const createDeal = async (inputData, salesAccountId, contactId) => {
  const { businessName, grub24SalesOwnerName } = inputData;
  const requestOptions = {
    method: "POST",
    headers: createHeaders(),
    body: JSON.stringify({
      deal: {
        name: `${businessName} Deal - New`,
        amount: 0,
        sales_account_id: salesAccountId,
        owner_id: grub24SalesOwnerName,
        contact_ids: [contactId],
        // "deal_type_id": 0,
        // "deal_stage_id": 0,
      },
    }),
  };

  return fetchFromApi("/crm/sales/api/deals", requestOptions);
};

// Function to upload a single file
export async function uploadPDF(inputData, salesAccountId, file) {
  const { businessName } = inputData;

  const formdata = new FormData();
  formdata.append("file", file);
  formdata.append("file_name", `Agreement-${businessName}`);
  formdata.append("is_shared", true);
  formdata.append("targetable_id", salesAccountId);
  formdata.append("targetable_type", "SalesAccount");

  const requestOptions = {
    method: "POST",
    headers: createHeaders(""),
    body: formdata,
  };

  return fetchFromApi("/crm/sales/api/documents", requestOptions);
}

// Function to upload a single file
export async function uploadDocumentToCRM(file, salesAccountId) {
  const base64ContentArray = file.content.split(",");
  const base64Data = base64ContentArray[1];
  const blob = base64ToBlob(base64Data, file.type);

  const formdata = new FormData();
  formdata.append("file", blob);
  formdata.append("file_name", file.name);
  formdata.append("is_shared", true);
  formdata.append("targetable_id", salesAccountId);
  formdata.append("targetable_type", "SalesAccount");

  const requestOptions = {
    method: "POST",
    headers: createHeaders(""),
    body: formdata,
  };

  return fetchFromApi("/crm/sales/api/documents", requestOptions);
}

export const checkBusinessName = async (businessName) => {
  try {
    const url = new URL(
      `${backendDomain}/crm/sales/api/filtered_search/sales_account`
    );
    url.searchParams.append("hostname", freshsalesDomain);

    const response = await fetch(url, {
      method: "POST",
      headers: createHeaders(),
      body: JSON.stringify({
        filter_rule: [
          {
            attribute: "name",
            operator: "is_in",
            value: businessName,
          },
        ],
      }),
    });

    const result = await response.json();
    if (result.sales_accounts[0]) {
      return true;
    } else {
      return false;
    } // Backend should return whether the name is a duplicate
  } catch (error) {
    console.error("Error checking business name:", error);
    return false;
  }
};

export const checkEmail = async (email) => {
  try {
    const url = new URL(
      `${backendDomain}/crm/sales/api/filtered_search/contact`
    );
    url.searchParams.append("hostname", freshsalesDomain);

    const response = await fetch(url, {
      method: "POST",
      headers: createHeaders(),
      body: JSON.stringify({
        filter_rule: [
          {
            attribute: "contact_email.email",
            operator: "is_in",
            value: email,
          },
        ],
      }),
    });

    const result = await response.json();
    if (result.contacts[0]) {
      return true;
    } else {
      return false;
    } // Backend should return whether the name is a duplicate
  } catch (error) {
    console.error("Error checking client details:", error);
    return false;
  }
};

export const fetchAllLists = async () => {
  const requestOptions = {
    method: "GET",
    headers: createHeaders(),
  };
  const result = await fetchFromApi("/crm/sales/api/lists", requestOptions);
  return result.lists;
};

export const addContactToList = async (listId, contactId) => {
  const requestOptions = {
    method: "PUT",
    headers: createHeaders(),
    body: JSON.stringify({
      ids: [contactId],
    }),
  };
  return fetchFromApi(
    `/crm/sales/api/lists/${listId}/add_contacts`,
    requestOptions
  );
};

import {
  Box,
  Container,
  Typography,
  Paper,
  Button,
  List,
  ListItem,
  Divider,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

function ErrorScreen({ error }) {
  const taskProgress = error.taskProgress || {};

  const tasks = [
    { name: "Account Creation", status: taskProgress.accountCreated },
    { name: "Contact Creation", status: taskProgress.contactCreated },
    { name: "Deal Creation", status: taskProgress.dealCreated },
    { name: "Adding to List", status: taskProgress.addedToList },
    { name: "PDF Upload", status: taskProgress.pdfUploaded },
    { name: "Menu Upload", status: taskProgress.menuUploaded },
    { name: "Website Task Creation", status: taskProgress.websiteTaskCreated },
    {
      name: "Google Page Task Creation",
      status: taskProgress.googlePageTaskCreated,
    },
    { name: "Flyer Task Creation", status: taskProgress.flyerTaskCreated },
  ];

  return (
    <Box sx={{ backgroundColor: "rgb(243, 243, 243)" }}>
      <Container maxWidth="sm">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="100vh"
        >
          <Paper elevation={3} sx={{ padding: 4, textAlign: "center" }}>
            <Typography variant="h4" gutterBottom color="error">
              Something went wrong!
            </Typography>
            <Typography variant="body1" gutterBottom>
              {error.message ||
                error ||
                "An unexpected error occurred. Please try again later."}
            </Typography>

            <Divider />
            <Typography variant="h5" gutterBottom mt={2}>
              Progress
            </Typography>

            <List>
              {tasks.map((task, index) => (
                <ListItem
                  key={index}
                  sx={{
                    color: task.status ? "green" : "red", // Green for completed, red for not completed
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {task.status ? (
                    <CheckCircleIcon sx={{ color: "green", mr: 1 }} />
                  ) : (
                    <CancelIcon sx={{ color: "red", mr: 1 }} />
                  )}
                  {task.name}: {task.status ? "Completed" : "Not Completed"}
                </ListItem>
              ))}
            </List>

            <Button
              variant="contained"
              color="primary"
              onClick={() => window.location.reload()}
              sx={{ mt: 2 }}
            >
              Retry
            </Button>
          </Paper>
        </Box>
      </Container>
    </Box>
  );
}

export default ErrorScreen;

import {
  Box,
  Container,
  CircularProgress,
  Typography,
  Paper,
} from "@mui/material";

function LoadingScreen() {
  return (
    <Box sx={{ backgroundColor: "rgb(243, 243, 243)" }}>
      <Container maxWidth="sm">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="100vh"
        >
          <Paper elevation={3} sx={{ padding: 4, textAlign: "center" }}>
            <CircularProgress
              sx={{ marginTop: 4, marginBottom: 4 }}
              size={50}
            />
            <Typography variant="h4" gutterBottom>
              Please wait, submission is being processed...
            </Typography>
          </Paper>
        </Box>
      </Container>
    </Box>
  );
}

export default LoadingScreen;

import { useMemo, useEffect } from "react";
import { Model } from "survey-core";
import { defaultSurveyJson } from "../surveyJson";
import { themeJson } from "../theme";
import moment from "moment-timezone";
import { checkBusinessName, fetchOwners } from "../utils/freshsalesApis";
import "survey-core/defaultV2.min.css";

const getCurrentDateTimeWithDST = () => {
  return moment().tz("Europe/London").format("YYYY-MM-DD HH:mm");
};

export function useSurveyModel(handleOnCompleting) {
  const surveyModel = useMemo(() => {
    const model = new Model(defaultSurveyJson);
    model.applyTheme(themeJson);

    const dateTimeQuestion = model.getQuestionByName("dateTime");
    if (dateTimeQuestion) {
      dateTimeQuestion.defaultValue = getCurrentDateTimeWithDST();
    }

    // *** businessName Validation ***
    model.onServerValidateQuestions.add(async (survey, options) => {
      const businessName = options.data["businessName"];
      const errors = {};
      if (businessName) {
        const isDuplicateBusinessName = await checkBusinessName(businessName);
        if (isDuplicateBusinessName) {
          errors["businessName"] =
            "This business name is already taken. Please choose another one.";
        }
      }
      options.errors = errors;
      options.complete();
    });

    model.onComplete.add(handleOnCompleting);

    return model;
  }, [handleOnCompleting]);

  useEffect(() => {
    const updateGrub24SalesOwnerNameChoices = async () => {
      try {
        const owners = await fetchOwners();
        const grub24SalesOwnerName = surveyModel.getQuestionByName(
          "grub24SalesOwnerName"
        );
        if (grub24SalesOwnerName) {
          grub24SalesOwnerName.choices = owners.users.map((user) => ({
            value: user.id,
            text: `${user.display_name} | ${user.email}`,
          }));
        }
      } catch (error) {
        console.error("Error fetching owners:", error);
      }
    };

    if (surveyModel) {
      updateGrub24SalesOwnerNameChoices();
    }

    const surveyContainerElement = document.getElementById("surveyContainer");
    if (surveyContainerElement) {
      surveyContainerElement.setAttribute("data-testid", "surveyContainer");
    }
  }, [surveyModel]);

  return surveyModel;
}

import { Box, Container, Typography, Paper, Button } from "@mui/material";

function SubmissionResult({ onDownloadPDF, pdfData }) {
  return (
    <Box sx={{ backgroundColor: "rgb(243, 243, 243)" }}>
      <Container maxWidth="md">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="100vh"
        >
          <Paper elevation={3} sx={{ textAlign: "center" }}>
            <Typography
              variant="h3"
              gutterBottom
              sx={{ mx: { xs: 3, sm: 8 }, marginTop: { xs: 4, sm: 8 } }}
            >
              Thank you for submitting the Customer Agreement
            </Typography>
            {pdfData && (
              <Button
                onClick={onDownloadPDF}
                variant="contained"
                color="success"
                sx={{
                  mx: { xs: 2, sm: 4 },
                  marginTop: { xs: 2, sm: 4 },
                  marginBottom: { xs: 1, sm: 5 },
                }}
              >
                Download Agreement PDF
              </Button>
            )}

            <Button
              variant="contained"
              color="primary"
              onClick={() => window.location.reload()}
              sx={{
                mx: { xs: 2, sm: 4 },
                marginTop: { xs: 2, sm: 4 },
                marginBottom: { xs: 3, sm: 5 },
              }}
            >
              Start New Form
            </Button>
          </Paper>
        </Box>
      </Container>
    </Box>
  );
}

export default SubmissionResult;

import { useCallback } from "react";
import {
  createAccount,
  upsertContact,
  createDeal,
  uploadPDF,
  uploadDocumentToCRM,
  fetchAllLists,
  addContactToList,
} from "../utils/freshsalesApis";
import { createTask, uploadFilesToZoho } from "../utils/zohoApis";
import { surveyGeneratePDF } from "../utils/pdfUtils";
import { defaultSurveyJson } from "../surveyJson";

export function useSurveySubmission(
  setPdfData,
  setIsSubmitted,
  setOnSubmitting,
  setError // Add setError to the parameters
) {
  return useCallback(async (sender) => {
    const taskProgress = {
      accountCreated: false,
      contactCreated: false,
      dealCreated: false,
      addedToList: false,
      pdfUploaded: false,
      menuUploaded: false,
      websiteTaskCreated: false,
      googlePageTaskCreated: false,
      flyerTaskCreated: false,
    };

    try {
      setOnSubmitting(true);
      const inputData = sender.data;

      const PDFBlob = await surveyGeneratePDF(defaultSurveyJson, sender);
      setPdfData(PDFBlob);

      await processSubmission(inputData, PDFBlob, taskProgress);
      setOnSubmitting(false);
      setIsSubmitted(true);
    } catch (error) {
      setOnSubmitting(false);
      setError({ message: error.message, taskProgress }); // Pass both error message and task progress
      console.error("Error processing submission:", error);
    }
    // eslint-disable-next-line
  }, []);

  async function processSubmission(inputData, PDFBlob, taskProgress) {
    try {
      // Create Account
      const account = await createAccount(inputData);
      taskProgress.accountCreated = true;

      // Upload PDF
      await uploadPDF(inputData, account.sales_account.id, PDFBlob);
      taskProgress.pdfUploaded = true;

      // Upload Documents to CRM
      if (inputData.menu && inputData.menu.length > 0) {
        for (const file of inputData.menu) {
          await uploadDocumentToCRM(file, account.sales_account.id);
          taskProgress.menuUploaded = true;
        }
      }

      // Upsert Contact
      let contact = await upsertContact(inputData, account.sales_account.id);
      taskProgress.contactCreated = true;
      // let contact = await upsertContact(
      //   inputData,
      //   account.sales_account.id
      //   // true
      // ).catch(async (error) => {
      //   if (error.message.includes("400")) {
      //     return await upsertContact(
      //       inputData,
      //       account.sales_account.id,
      //       false
      //     );
      //   } else {
      //     throw new Error("Error during contact creation");
      //   }
      // });

      // Create Deal
      await createDeal(inputData, account.sales_account.id, contact.contact.id);
      taskProgress.dealCreated = true;

      // Add to List
      const lists = await fetchAllLists();
      const newClientsList = lists.find((list) => list.name === "New Clients");
      if (newClientsList) {
        await addContactToList(newClientsList.id, contact.contact.id);
        taskProgress.addedToList = true;
      }

      // Handle Requests
      if (inputData?.requests?.includes("website")) {
        let uploadedFileIds = [];
        if (inputData.menu && inputData.menu.length > 0) {
          for (const file of inputData.menu) {
            const uploadedFileId = await uploadFilesToZoho(file);
            uploadedFileIds.push(uploadedFileId);
          }
        }
        await createTask("website", inputData.businessName, uploadedFileIds);
        taskProgress.websiteTaskCreated = true;
      }

      if (inputData?.requests?.includes("googlePage")) {
        await createTask("googlePage", inputData.businessName);
        taskProgress.googlePageTaskCreated = true;
      }

      if (inputData?.requests?.includes("flyer")) {
        await createTask("flyer", inputData.businessName);
        taskProgress.flyerTaskCreated = true;
      }
    } catch (error) {
      throw new Error(`Error in ${getFailedTaskMessage(taskProgress)}`);
    }
  }

  // Helper function to determine where the process failed
  function getFailedTaskMessage(taskProgress) {
    if (!taskProgress.accountCreated) return "Account creation";
    if (!taskProgress.pdfUploaded) return "PDF upload";
    if (!taskProgress.menuUploaded) return "Menu upload";
    if (!taskProgress.contactCreated) return "Contact creation";
    if (!taskProgress.dealCreated) return "Deal creation";
    if (!taskProgress.addedToList) return "Adding to list";
    if (!taskProgress.websiteTaskCreated) return "Website task creation";
    if (!taskProgress.googlePageTaskCreated) return "Google Page task creation";
    if (!taskProgress.flyerTaskCreated) return "Flyer task creation";
    return "Unknown task";
  }
}

import React from "react";
import { createRoot } from "react-dom/client"; // Import createRoot
import Authentication from "./Authentication";
import reportWebVitals from "./reportWebVitals";
import { theme } from "./theme";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import "./index.css";

// Get the root element from the DOM
const container = document.getElementById("root");
const root = createRoot(container); // Create a root with createRoot

// Render the app
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Authentication />
    </ThemeProvider>
  </React.StrictMode>
);

// For performance measurement
reportWebVitals();

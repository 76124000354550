import { Survey } from "survey-react-ui";

function SurveyForm({ surveyModel }) {
  return (
    <div id="pdfContent" className="pdfContent">
      <Survey
        model={surveyModel}
        id="surveyContainer"
        // data-testid="surveyContainer" // data-testid is added on useSurveyModel.js
      />
    </div>
  );
}

export default SurveyForm;

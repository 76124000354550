import { base64ToBlob } from "./documentUtils";

const zohoDomain = process.env.REACT_APP_ZOHO_CONNECT_DOMAIN;
const backendDomain = process.env.REACT_APP_BACKEND_DOMAIN;
const backendAuthApiKey = process.env.REACT_APP_BACKEND_AUTH_API_KEY;

const createHeaders = (ContentType = "application/json") => {
  const myHeaders = new Headers();
  if (ContentType) myHeaders.append("Content-Type", ContentType);
  myHeaders.append("Authorization", `Bearer ${backendAuthApiKey}`);
  return myHeaders;
};

const fetchFromApi = async (url, requestOptions) => {
  try {
    const response = await fetch(url, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error(`Error fetching ${url}:`, error);
    throw error;
  }
};

export const uploadFilesToZoho = async (file) => {
  const formData = new FormData();
  const base64ContentArray = file.content.split(",");
  const base64Data = base64ContentArray[1];
  const blob = base64ToBlob(base64Data, file.type);
  formData.append("previewFile", blob, file.name);

  const requestOptions = {
    method: "POST",
    headers: createHeaders(""), // No Content-Type for multipart/form-data
    body: formData,
  };

  const url = new URL(`${backendDomain}/pulse/api/uploadFiles`);
  url.searchParams.append("hostname", zohoDomain);
  url.searchParams.append("auth_scope", "zohopulse.attachments.CREATE");
  url.searchParams.append("scopeID", "57609000000002002");
  const result = await fetchFromApi(url, requestOptions);
  return result.uploadFiles.uploadedFiles[0];
};

export const createTask = async (boardName, businessName, fileIds) => {
  if (!businessName) throw new Error("Business name is required");
  if (!boardName) throw new Error("Board name is required");

  const dueDate = new Date();
  dueDate.setDate(dueDate.getDate() + 7);

  const eyear = dueDate.getFullYear();
  const emonth = dueDate.getMonth() + 1; // Months are zero-indexed
  const edate = dueDate.getDate();

  const url = new URL(`${backendDomain}/pulse/api/addTask`);
  url.searchParams.append("hostname", zohoDomain);
  url.searchParams.append("auth_scope", "zohopulse.tasks.CREATE");
  url.searchParams.append("title", businessName);
  url.searchParams.append("scopeID", "57609000000002002");
  url.searchParams.append("eyear", eyear);
  url.searchParams.append("emonth", emonth.toString().padStart(2, "0")); // Ensure two digits
  url.searchParams.append("edate", edate.toString().padStart(2, "0")); // Ensure two digits

  switch (boardName) {
    case "website":
      url.searchParams.append(
        "desc",
        `Please design a website for ${businessName}`
      );
      url.searchParams.append("boardId", "57609000000002095");
      url.searchParams.append("sectionId", "57609000000002105");
      url.searchParams.append("priority", "Low");
      url.searchParams.append("userIds", "20085878062,20075385257");
      break;

    case "googlePage":
      url.searchParams.append(
        "desc",
        `Please get a google page of ${businessName}`
      );
      url.searchParams.append("boardId", "57609000000014157");
      url.searchParams.append("sectionId", "57609000000016031");
      url.searchParams.append("priority", "Low");
      url.searchParams.append("userIds", "20092777207");
      break;

    case "flyer":
      url.searchParams.append(
        "desc",
        `Please design a flyer for ${businessName}`
      );
      url.searchParams.append("boardId", "57609000000003146");
      url.searchParams.append("sectionId", "57609000000003156");
      url.searchParams.append("priority", "Low");
      url.searchParams.append("userIds", "20092777207");
      break;

    default:
      break;
  }

  if (fileIds && fileIds.length > 0) {
    url.searchParams.append("fileIds", fileIds.join(","));
  }

  const requestOptions = {
    method: "POST",
    headers: createHeaders(""),
  };

  return fetchFromApi(url, requestOptions);
};
